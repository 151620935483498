<template>
  <div class="line-items">
    <div class="line-items__back" @click="changeView('Settings')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>{{ $t("settings.back") }}</span>
    </div>
    <div class="line-items__elements-container">
      <div class="line-items__buttons-toolbar">
        <div class="line-items-searcher">
          <input
            type="text"
            class="line-items-searcher__searcher"
            v-model="lineItemsSearcher"
            placeholder="Search a commodity ..."
            @keyup.enter="searchLineItem(lineItemsSearcher)"
          />
        </div>
        <div class="line-items-buttons">
          <button
            class="create-line-items-button"
            @click="handleShowModal(true), resetSelectedLineItems()"
          >
            New Commodity
          </button>
        </div>
        <CustomModal
          v-model="showModal"
          size="md"
          :centered="true"
          :showOverlay="sendingRequest"
          :closeOnBackDrop="false"
          :closeOnEsc="false"
          :modalTitle="isUpdating ? 'Edit Commodity' : 'New Commodity'"
        >
          <template #modal-content>
            <NewLineItemModal
              :selectedLineItem="selectedLineItem"
              :showModal="showModal"
              :isUpdating="isUpdating"
              @createLineItem="createLineItem"
              @updateLineItem="updateLineItem"
              @handleShowModal="handleShowModal"
            ></NewLineItemModal>
          </template>
        </CustomModal>
      </div>
      <LineItemsTable
        :loading="loading"
        :lineItemsToShow="lineItemsToShow"
        @handleIsUpdating="handleIsUpdating"
        @getAllLineItems="getAllLineItems"
        @deleteLineItems="deleteLineItems"
        @getSelectedLineItem="getSelectedLineItem"
        @handleShowModal="handleShowModal"
      >
      </LineItemsTable>
    </div>
  </div>
</template>

<script>
import CustomModal from "../../../components/CustomModal.vue";
import LineItemsTable from "./components/LineItemsTable.vue";
import NewLineItemModal from "./components/NewLineItemModal.vue";

export default {
  name: "MyLineItems",
  components: {
    LineItemsTable,
    NewLineItemModal,
    CustomModal,
  },
  data() {
    return {
      sendingRequest: false,
      showModal: false,
      loading: true,
      isUpdating: false,
      updating: false,
      selectedLineItem: [],
      searchingCompanies: false,
      skip: 0,
      limit: 100,
      lineItemsSearcher: "",
      dataToUpdate: null,
      lineItemsToShow: null,
      lineItemsData: [
        {
          handling_units: "Pallets",
          dimension_units: "in",
          weight_units: "lbs",
          nmfc_class: null,
          height: "",
          length: "",
          width: "",
          weight: "",
          // New fields:
          user_height: "",
          user_length: "",
          user_width: "",
          user_weight: "",
          user_dimension_units: "in",
          user_weight_units: "lbs",
          pickup_stop: "pickup",
          delivery_stop: "delivery",
          linear_feet: 0.1,
          description: "",
          handling_unit_count: "",
          hazardous_materials: false,
        },
      ],
    };
  },
  watch: {
    showModal(value) {
      if (!value) {
        this.handleIsUpdating(false);
      }
    },
  },
  async created() {
    await this.getAllLineItems();
    this.resetSelectedLineItems();
  },
  methods: {
    async getAllLineItems() {
      this.loading = true;
      this.lineItemsToShow = await this.$store.dispatch(
        "accountCommodity/getAllAccountComodityInfo"
      );
      this.loading = false;
    },
    async createLineItem(data) {
      this.sendingRequest = true;
      const response = await this.$store.dispatch("accountCommodity/createAccountCommodity", data);
      if (response.status === 201) {
        this.alertMessage({
          type: "success",
          message: "Commodity successfully create",
          duration: "3000",
        });
        this.addLineItemTable(data);
      }
      this.sendingRequest = false;
      this.handleShowModal(false);
    },
    async updateLineItem(data) {
      this.sendingRequest = true;
      const { id } = data;
      delete data.id;
      const response = await this.$store.dispatch("accountCommodity/updateAccountCommodity", {
        id,
        body: data,
      });
      if (response.status === 200) {
        this.alertMessage({
          type: "success",
          message: "Commodity successfully update",
          duration: "3000",
        });
      }
      this.isUpdating = false;
      this.getAllLineItems();
      this.sendingRequest = false;
      this.handleIsUpdating(false);
      this.handleShowModal(false);
    },
    async deleteLineItems(data) {
      const deleteConfirmation = await this.swal({
        title: "Do you want to delete this commodity permanently?",
        icon: "warning",
        buttons: true,
      });
      if (!deleteConfirmation) {
        return;
      }
      this.updating = true;
      await this.$store.dispatch("accountCommodity/deleteAccountCommodity", data.id);
      this.$message({
        type: "success",
        message: "Commodity successfully deleted",
        duration: 3000,
      });
      this.updating = false;
      this.removeLineItemTable(data);
    },

    handleShowModal(data) {
      this.showModal = data;
      if (!data) {
        this.handleIsUpdating(false);
      }
    },
    handleIsUpdating(data) {
      this.isUpdating = data;
    },
    getSelectedLineItem(data) {
      this.selectedLineItem = data;
    },
    addLineItemTable(lineItem) {
      this.lineItemsToShow.push(lineItem);
      this.getAllLineItems();
    },
    removeLineItemTable(data) {
      const index = this.lineItemsToShow.findIndex((item) => item.id === data.id);
      /* If the element was found, remove it from the array. */
      if (index !== -1) {
        this.lineItemsToShow.splice(index, 1);
      }
    },
    alertMessage({ type, message, duration }) {
      this.$message({
        type,
        message,
        duration,
      });
    },
    async searchLineItem(search) {
      this.loading = true;
      this.lineItemsToShow = await this.$store.dispatch(
        "accountCommodity/getAllAccountComodityInfo",
        {
          skip: this.skip,
          limit: this.limit,
          description: search,
        }
      );
      this.loading = false;
    },
    resetSelectedLineItems() {
      this.selectedLineItem = this.lineItemsData;
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-line-items-button {
  @include primary-button($heightSize: 40px);
  @include font-button-text;
  min-width: 150px;
  padding: 5px 3%;
  font-weight: 400;
  i {
    font-size: 16px;
  }
}
.line-items {
  padding: 0px 5px;
  padding-top: 2%;
  padding-bottom: 1px;
  &__buttons-toolbar {
    margin: 20px 0px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 8px;
  }
  &__elements-container {
    margin: 0px 10px;
  }
  &__back {
    display: flex;
    align-items: center;
    margin-left: 1%;
    @include font-small-standart-text;
    font-weight: bold;
    width: 160px;
    cursor: pointer;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  &__not-found {
    color: $color-primary-company;
    margin-top: 200px;
    p {
      margin-bottom: 0;
    }
  }
  &__not-found-icon {
    font-size: 50px;
  }
}

.loading {
  margin-top: 450px;
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}

.line-items-searcher {
  position: relative;
  display: flex;
  width: 30%;
  min-width: 200px;
  height: 40px;
  padding: 6px;
  border-radius: 15px;
  border: 1px solid $color-border-container;
  @media (max-width: 500px) {
    &:nth-of-type(1) {
      order: 3;
    }
  }
  &__searcher {
    width: 100%;
    margin-left: 5px;
    padding-right: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    letter-spacing: 0px;
    color: $color-primary-company;
  }
  &__spinner {
    color: #737373;
    position: absolute;
    top: 25%;
    right: 2%;
  }
  &__spinner-icon {
    height: 16px;
    width: 16px;
    color: #5a5a5a;
    position: relative;
    display: inline-block;
    border: 3px solid;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 1s linear infinite;
  }
  &__delete-button {
    cursor: pointer;
    color: #737373;
    position: absolute;
    top: 20%;
    right: 2%;
  }
}

::v-deep .bg-light {
  background-color: $color-white !important;
}

::v-deep {
  .rc-searcher-container {
    font-size: 14.5px;
  }
}
</style>
