<template>
  <div class="line-items-modal">
    <div class="line-items-modal__content">
      <p class="line-items-modal__instruction" v-if="!isUpdating">
        Customize your commodity, specifying units, weight, dimensions, class, and name. When
        selected in the quote, this information will be automatically filled in.
      </p>
      <LineItems
        v-model="dataToSend"
        class="line-items-container"
        :lineItems="selectedLineItem"
        :showFeatureSearchItemAndAddMoreItems="false"
        ref="lineItem"
      />
      <div class="button-toolbar">
        <button class="button-toolbar__cancel" @click="emitShowModal(false)">
          {{ $t("userManagement.cancel") }}
        </button>
        <button class="button-toolbar__save" @click="triggerButtonAction()">
          {{ isUpdating ? "Update" : $t("userManagement.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LineItems from "../../../QuoteBook/LoadCreation/lineItems/LineItems.vue";

export default {
  name: "NewLIneItemModal",

  components: {
    LineItems,
  },
  props: {
    selectedLineItem: Array,
    showModal: Boolean,
    isUpdating: Boolean,
  },
  data() {
    return {
      dataToSend: [],
      payload: null,
    };
  },
  methods: {
    deleteUnnecessaryProperties(data) {
      delete data[0].delivery_stop;
      delete data[0].pickup_stop;
      delete data[0].hazardous_materials;
      delete data[0].linear_feet;
      return data[0];
    },
    async createNewLineItem() {
      const payload = this.deleteUnnecessaryProperties(this.dataToSend);
      this.$emit("createLineItem", payload);
    },
    async updateLineItem() {
      const payload = this.deleteUnnecessaryProperties(this.dataToSend);
      this.$emit("handleIsUpdating", true);
      this.$emit("updateLineItem", payload);
    },
    async triggerButtonAction() {
      const validationLineItems = await this.$refs.lineItem.validateFormfields();
      if (!validationLineItems) {
        return;
      }
      if (this.isUpdating) {
        this.updateLineItem();
      } else {
        this.createNewLineItem();
      }
    },
    emitShowModal(data) {
      this.$emit("handleShowModal", data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/RCComponents.scss";
@import "@/assets/scss/components/Checkboxes.scss";

.line-items-modal {
  &__button {
    @include primary-button($heightSize: 40px);
    @include font-button-text;
    min-width: 150px;
    padding: 5px 3%;
    font-weight: 400;
    i {
      font-size: 16px;
    }
  }
  @include font-standart-text;
  &__header {
    display: flex;
  }
  &__modal-title {
    @include modals-title;
    color: $color-border-container;
  }
  &__close-button {
    text-align: right;
  }
  &__instruction {
    text-align: left;
    margin: 0px;
  }
  &__input {
    margin-bottom: 15px;
    &:focus {
      outline: none;
    }
    &--searcher {
      margin-bottom: 15px;
    }
  }
}

.button-toolbar {
  display: flex;
  justify-content: space-around;
  &__save {
    @include primary-button;
    background-color: $color-save-button;
    padding: 7px;
    width: 47.5%;
    margin: 0px;
    &:hover {
      background-color: $color-save-button;
    }
  }
  &__cancel {
    @include opposite-secondary-button;
    @include secondary-button();
    outline: 0;
    padding: 7px;
    width: 47.5%;
  }
}

::v-deep {
  .lineitem-row {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
  }
  .nmfc__dropdown {
    width: 489%;
  }
  .nmfc {
    margin-top: 0px !important;
  }
  .commodity {
    margin-top: 0px !important;
  }
}
</style>
