<template>
  <div class="line-item-table" ref="agGridTableContainer">
    <!-- Table -->
    <el-table :data="lineItemsToShow" :height="tableHeight" v-loading="loading">
      <el-table-column
        v-for="(column, index) in columnDefs"
        :key="index"
        :label="column.label"
        :prop="column.field"
        :sortable="column.sortable"
      >
      </el-table-column>
      <el-table-column :width="60" align="center">
        <template slot-scope="scope">
          <div class="actions-container">
            <div>
              <button class="delete-line-item-button" @click="deleteLineItem(scope.row)">
                <i class="ion ion-trash-outline"></i>
              </button>
            </div>
            <div>
              <button class="edit-line-item-button" @click="editLineItem(scope.row)">
                <img src="@/assets/scss/icons/pen-to-square-regular.svg" />
              </button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import "@/assets/scss/components/agGridStyleOverride.scss";
import OnResize from "../../../../Extend/onResize";

export default {
  name: "LineItemsTable",
  props: {
    lineItemsToShow: Array,
    loading: Boolean,
  },
  mixins: [OnResize],
  data() {
    return {
      tableHeight: null,
      columnDefs: [
        {
          label: "Commodity",
          field: "description",
        },
        {
          label: "Units",
          field: "handling_unit_count",
        },
        {
          label: "Type",
          field: "handling_units",
        },
        {
          label: "Weight",
          field: "weight",
        },
        {
          label: "Weight Units",
          field: "user_weight_units",
        },
        {
          label: "Length",
          field: "length",
        },
        {
          label: "Width",
          field: "width",
        },
        {
          label: "Height",
          field: "height",
        },
        {
          label: "Dim Units",
          field: "user_dimension_units",
        },
        {
          label: "NMFC class",
          field: "nmfc_class",
        },
      ],
    };
  },
  watch: {
    screenWidth() {
      const clientHeight = window.innerHeight;
      this.tableHeight = clientHeight - 260;
    },
  },
  created() {},
  methods: {
    deleteLineItem(payload) {
      this.$emit("deleteLineItems", payload);
    },
    editLineItem(payload) {
      this.$emit("handleShowModal", true);
      this.$emit("handleIsUpdating", true);
      this.$emit("getSelectedLineItem", [payload]);
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-line-item-button {
  color: $color-remove-inactive-button;
  padding: 0px;
  background: none;
  border: none;
  font-size: 16px;
}
.line-item-table {
  @include table-design(4);
}

.edit-line-item-button {
  background: none;
  border: none;
  outline: none;
  padding: none;
  margin: none;
  margin: 0px;
  padding: 0px;
  color: $color-primary-company;
}
.actions-container {
  display: flex;
  gap: 6px;
}
::v-deep {
  .cell-text-container__text {
    white-space: normal;
  }
  .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
